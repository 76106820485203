import {useMediaQuery} from "@react-hook/media-query";
import {useCallback, useEffect, useState} from "react";
import * as React from "react";
import Section from "../../Layout/Section";
import SectionTitle from "../../Layout/Section/Title";
import Media from "../../Common/Media";
import TemplateString from "../../Common/TemplateString";
import * as listStyles from "./list.module.css";

const List = ({title, listItems, marker, footnotes}) => {
    const isOneColumnLayout = useMediaQuery("(max-width: 768px)");

    const [columns, setColumns] = useState([]);

    const getItem = useCallback(
        ({id, content}) => {
            return (
                <div key={id} className={listStyles.list__item}>
                    {marker && (
                        <Media media={{content: marker, youtubeVideoURL: ""}} className={listStyles.list__itemMarker} />
                    )}
                    <p>{content}</p>
                </div>
            );
        },
        [marker],
    );

    useEffect(() => {
        setColumns(() => {
            if (isOneColumnLayout) {
                return listItems.map(getItem);
            }

            return listItems
                .reduce(
                    (acc, {id, content}, idx) => {
                        acc[idx % 2].push(getItem({id, content}));
                        return acc;
                    },
                    [[], []],
                )
                .map((col, idx) => <div key={`col_${idx}`}>{col}</div>);
        });
    }, [getItem, isOneColumnLayout, listItems]);

    return (
        <Section>
            <div className={listStyles.list__wrapper}>
                <SectionTitle>
                    <TemplateString template={title} />
                </SectionTitle>
                <div className={listStyles.list}>{columns}</div>
                <div>
                    {footnotes.map(({id, content}, idx) => (
                        <p key={id} className={listStyles.footnotes__item}>
                            <span>{new Array(idx + 1).fill("*").join("")}&nbsp;</span>
                            <TemplateString template={content} />
                        </p>
                    ))}
                </div>
            </div>
        </Section>
    );
};

export default List;
