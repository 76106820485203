// extracted by mini-css-extract-plugin
export var hero = "hero-module--hero--nEsGq";
export var hero_interactive = "hero-module--hero_interactive--ooJlm";
export var hero__title = "hero-module--hero__title--Fke2G";
export var hero__desc = "hero-module--hero__desc--prIRs";
export var hero__button = "hero-module--hero__button--8PpiQ";
export var hero__section = "hero-module--hero__section--8jf83";
export var hero__section_interactive = "hero-module--hero__section_interactive--hw2o5";
export var hero__gradient = "hero-module--hero__gradient--X5NQ0";
export var hero__container = "hero-module--hero__container--ANVJP";
export var hero__kicker = "hero-module--hero__kicker--SiUx3";
export var hero__gradient_tablet = "hero-module--hero__gradient_tablet--Rq5mb";
export var hero__gradient_mobile = "hero-module--hero__gradient_mobile--J9ARE";
export var hero_textLeft = "hero-module--hero_text-left--wwoNr";
export var hero__media = "hero-module--hero__media--tapgP";