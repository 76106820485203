import classNames from "classnames/bind";
import {StaticImage} from "gatsby-plugin-image";
import PropTypes from "prop-types";
import {useCallback, useState} from "react";
import * as React from "react";
import Section from "../Layout/Section";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import Button from "../UI/Button";
import * as heroStyles from "./hero.module.css";

const cx = classNames.bind(heroStyles);

const Hero = ({title, description, media, type, variant}) => {
    const [isInteractive] = useState(type === "interactive");

    const scrollToForm = useCallback(() => {
        const form = document.querySelector("#request-form");
        if (!form) {
            return;
        }

        form.scrollIntoView({behavior: "smooth"});
    }, []);

    const heroClassNames = cx({
        hero: true,
        hero_interactive: isInteractive,
        hero_textLeft: variant === "text_left",
    });

    return (
        <Section
            className={cx({
                hero__section: true,
                hero__section_interactive: isInteractive,
            })}
            containerClassName={heroStyles.hero__container}
        >
            <div className={heroClassNames}>
                <div>
                    {isInteractive && <span className={heroStyles.hero__kicker}>программа обучения</span>}
                    <h1 className={heroStyles.hero__title}>
                        <TemplateString template={title} />
                    </h1>
                    <p className={heroStyles.hero__desc}>
                        <TemplateString template={description} />
                    </p>
                    <Button type="button" variant="primary" className={heroStyles.hero__button} onClick={scrollToForm}>
                        {isInteractive ? "Оставить заявку" : "Запросить лекции"}
                    </Button>
                </div>
                {media && <Media media={media} />}
            </div>
            <div>
                {isInteractive ? (
                    <>
                        <div className={heroStyles.hero__gradient} />
                        <div className={heroStyles.hero__gradient} />
                        <div className={heroStyles.hero__gradient} />
                    </>
                ) : (
                    <>
                        <StaticImage
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            src="../../assets/images/course/hero/gradient_desktop.png"
                            className={heroStyles.hero__gradient}
                        />
                        <StaticImage
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            src="../../assets/images/course/hero/gradient_tablet.png"
                            className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_tablet}`}
                        />
                        <StaticImage
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            loading="eager"
                            src="../../assets/images/course/hero/gradient_mobile.png"
                            className={`${heroStyles.hero__gradient} ${heroStyles.hero__gradient_mobile}`}
                        />
                    </>
                )}
            </div>
        </Section>
    );
};

Hero.defaultProps = {
    title: "",
    description: "",
    variant: "text_above",
    media: undefined,
    type: "interactive",
};

Hero.propTypes = {
    variant: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    media: PropTypes.shape({
        youtubeVideoURL: PropTypes.string,
        content: PropTypes.shape({
            mime: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
            localFile: PropTypes.shape({
                publicURL: PropTypes.string,
                childImageSharp: PropTypes.shape({
                    gatsbyImageData: PropTypes.shape({}),
                }),
            }),
        }),
    }),
    type: PropTypes.oneOf(["interactive", "online"]),
};

export default Hero;
