import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import Container from "../../Layout/Container";
import ContentBlock from "../../Layout/ContentBlock";
import Media from "../../Common/Media";
import TemplateString from "../../Common/TemplateString";
import * as aboutStyles from "./about.module.css";

const About = ({title, description, media, benefits}) => {
    const aboutMedia = (
        <div className={aboutStyles.about__media}>
            <Media media={media} />
            <div>
                <div className={aboutStyles.about__mediaGradient} />
                <div className={aboutStyles.about__mediaGradient} />
            </div>
        </div>
    );

    return (
        <div>
            <ContentBlock
                alignLeft
                alignTop
                title={<TemplateString template={title} />}
                desc={<TemplateString template={description} />}
                rightContent={aboutMedia}
                className={aboutStyles.about__main}
            />
            <Container>
                <div className={aboutStyles.about__benefitsWrapper}>
                    <div className={aboutStyles.about__benefits}>
                        {benefits.map(({id, title: benefitTitle, icon}) => {
                            return (
                                <div key={id} className={aboutStyles.about__benefitsItem}>
                                    {icon && (
                                        <Media
                                            media={{content: icon, youtubeVideoURL: ""}}
                                            className={aboutStyles.about__benefitsItemImage}
                                        />
                                    )}
                                    <p className={aboutStyles.about__benefitsItemTitle}>
                                        <TemplateString template={benefitTitle} />
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    <StaticImage
                        alt=""
                        layout="fixed"
                        placeholder="none"
                        loading="eager"
                        src="../../../assets/images/course/benefits/gradient_desktop.png"
                        className={aboutStyles.about__benefitsGradient}
                    />
                    <StaticImage
                        alt=""
                        layout="fixed"
                        placeholder="none"
                        loading="eager"
                        src="../../../assets/images/course/benefits/gradient_tablet.png"
                        className={`${aboutStyles.about__benefitsGradient} ${aboutStyles.about__benefitsGradient_tablet}`}
                    />
                    <StaticImage
                        alt=""
                        layout="fixed"
                        placeholder="none"
                        loading="eager"
                        src="../../../assets/images/course/benefits/gradient_mobile.png"
                        className={`${aboutStyles.about__benefitsGradient} ${aboutStyles.about__benefitsGradient_mobile}`}
                    />
                </div>
            </Container>
        </div>
    );
};

export default About;
