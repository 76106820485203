import * as React from "react";
import Layout from "../components/Layout/layout";
import Seo from "../components/SEO/SEO";
import Hero from "../components/Course/Hero";
import About from "../components/Course/Content/About";
import List from "../components/Course/Content/List";
import Grid from "../components/Course/Content/Grid";
import Program from "../components/Course/Program";
import CourseRequestForm from "../components/School/CourseRequestForm";

const contentComponents = {
    "course.about": About,
    "course.list": List,
    "course.grid": Grid,
};

const CoursePageTemplate = ({pageContext: {courseData}}) => {
    const {hero, formTitle, formDescription, content, program, seo} = courseData;

    return (
        <Layout>
            <Seo seo={seo} />
            <Hero {...hero} />
            {content?.map((contentPart) => {
                const Component = contentComponents[contentPart.strapi_component];

                return (
                    Component && (
                        <Component key={`${contentPart.strapi_component}_${contentPart.id}`} {...contentPart} />
                    )
                );
            })}
            <Program {...program} />
            <CourseRequestForm title={formTitle} desc={formDescription} />
        </Layout>
    );
};

export default CoursePageTemplate;
