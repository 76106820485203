import classNames from "classnames";
import {useCallback, useState} from "react";
import * as React from "react";
import Close from "../../assets/svg/close.svg";
import Section from "../Layout/Section";
import SectionTitle from "../Layout/Section/Title";
import TemplateString from "../Common/TemplateString";
import * as programStyles from "./program.module.css";

const Program = ({programItems}) => {
    const [openedTabs, setOpenedTabs] = useState(new Set());

    const toggleTab = useCallback(
        (id) => {
            setOpenedTabs((currentSet) => {
                const newSet = new Set(currentSet.values());

                if (currentSet.has(id)) {
                    newSet.delete(id);
                } else {
                    newSet.add(id);
                }

                return newSet;
            });
        },
        [setOpenedTabs],
    );

    return (
        <Section>
            <div className={programStyles.program__wrapper}>
                <SectionTitle>Программа курсов</SectionTitle>
                <ul className={programStyles.program}>
                    {programItems.map(({id, title, content}, idx) => {
                        return (
                            <li
                                key={id}
                                className={classNames(programStyles.program__item, {
                                    [programStyles.program__item_visible]: openedTabs.has(id),
                                })}
                            >
                                <p className={programStyles.program__itemTitle}>
                                    {idx + 1}. {title}
                                </p>
                                <p className={programStyles.program__itemDesc}>
                                    <TemplateString template={content} />
                                </p>
                                <Close className={programStyles.program__itemIcon} />
                                <button
                                    className={programStyles.program__itemButton}
                                    aria-label={`Раскрыть пункт №${idx + 1}`}
                                    type="button"
                                    onClick={() => toggleTab(id)}
                                />
                            </li>
                        );
                    })}
                    <li aria-hidden="true" className={programStyles.program__gradient} />
                </ul>
            </div>
        </Section>
    );
};

export default Program;
