import * as React from "react";
import Section from "../../Layout/Section";
import SectionTitle from "../../Layout/Section/Title";
import Media from "../../Common/Media";
import TemplateString from "../../Common/TemplateString";
import * as gridStyles from "./grid.module.css";

const Grid = ({title, columnCount, gridItems}) => {
    return (
        <Section>
            <div className={gridStyles.grid__wrapper}>
                <SectionTitle>
                    <TemplateString template={title} />
                </SectionTitle>
                <div className={gridStyles.grid} style={{"--column-count": columnCount}}>
                    {gridItems.map(({id, title: itemTitle, description, marker}) => {
                        return (
                            <div key={id}>
                                {marker && (
                                    <Media
                                        media={{content: marker, youtubeVideoURL: ""}}
                                        className={gridStyles.grid__itemIcon}
                                    />
                                )}
                                <h3 className={gridStyles.grid__itemTitle}>{itemTitle}</h3>
                                <p className={gridStyles.grid__itemDesc}>{description}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Section>
    );
};

export default Grid;
