// extracted by mini-css-extract-plugin
export var about__main = "about-module--about__main--0LQ6Z";
export var about__media = "about-module--about__media--CHJAj";
export var about__mediaGradient = "about-module--about__media-gradient--cKcED";
export var about__benefits = "about-module--about__benefits--+fPfP";
export var about__benefitsItem = "about-module--about__benefits-item--NPNoZ";
export var about__benefitsItemImage = "about-module--about__benefits-item-image--9iQ1j";
export var about__benefitsItemTitle = "about-module--about__benefits-item-title--1PIUV";
export var about__benefitsWrapper = "about-module--about__benefits-wrapper--InkB7";
export var about__benefitsGradient = "about-module--about__benefits-gradient--ktm0v";
export var about__benefitsGradient_tablet = "about-module--about__benefits-gradient_tablet--skKB1";
export var about__benefitsGradient_mobile = "about-module--about__benefits-gradient_mobile--j7Hjl";